html,
body {
  font-family: "Open Sans", sans-serif;
  background-color: white;
}

a {
  color: black;
}

a:hover {
  color: #c62828;
  text-decoration: none;
}

::selection {
  color: #e8e6e1;
  background: #c62828;
}

.btn {
  background-color: #c62828;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4), 0 0 6px rgba(0, 0, 0, 0.05);
}

.btn:hover {
  transform: scale(0.95, 0.95);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25),
    -5px -5px 15px 10px rgba(0, 0, 0, 0.22);
}

.deli {
  margin-left: 45px;
}

.horizontal-line {
  border-top: 3px solid #c62828;
  width: 20%;
  margin: 0 auto;
  padding-bottom: 30px;
}

/* NAV */
.navbar {
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

/* #mainNav, */
.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: rgba(0.5, 0.5, 0.5, 0.5);
  transition: background-color 0.2s ease;
}

#mainNav .navbar-brand,
.dropdown-item {
  font-weight: 700;
  color: white;
}

#mainNav .navbar-nav .nav-item .nav-link,
.dropdown-item {
  font-weight: 700;
  font-size: 0.9rem;
  padding: 10px;
}
#mainNav .navbar-nav .nav-item .nav-link:hover,
#mainNav .navbar-nav .nav-item .nav-link:active {
  color: #c62828;
}
#mainNav .navbar-nav .nav-item .nav-link.active {
  color: #c62828 !important;
}

.dropdown-item:active:focus {
  color: #ffffff;
  background-color: #c62828;
}

/* JUMBTRON */

header.masthead {
  padding-top: 120px;
  padding-bottom: calc(10rem - 4.5rem);
  background: url("../src/img/meatpic.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

.tagLine {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin: 30px;
}

.headline {
  font-family: "Playfair Display", serif;
  color: white;
  font-weight: bold;
  font-size: 7rem;
}

.container-jumbotron {
  max-width: 80%;
  height: auto;
  margin: 0 auto;
}

/* ABOUT US */

.aboutHeadline {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 45px;
}

.page-section-about {
  padding: 8rem 0;
  background-color: #e8e6e1;
}

.butcherLogo {
  margin-left: 2%;
  float: left;
  height: 250px;
  padding-right: 20px;
}

.text-muted {
  margin-left: 60px;
  margin: 0px;
}

/* MENU */

.page-section-menu {
  padding: 8rem 0;
}

.catering {
  text-transform: uppercase;
  font-weight: bold;
}

/* Contact Us */
.page-section-contact {
  padding: 8rem 0;
  background-color: #e8e6e1;
}

li {
  list-style: none;
}

.hours,
.follow {
  text-transform: uppercase;
  font-weight: bold;
}

.error {
  padding-top: 10px;
  color: #c62828;
  text-transform: uppercase;
  font-weight: bold;
}

i {
  color: #c62828;
  padding: 10px;
}

b {
  font-size: 20px;
}

/* FOOTER */

footer {
  text-transform: uppercase;
}
/*  responsive */

@media (min-width: 992px) {
  header.masthead {
    height: 800px;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .btn {
    margin-top: 25px;
  }
  .deli {
    margin: 0;
  }
  .hours {
    text-align: center;
  }
  .tagLine {
    font-size: 25px;
  }
  .headline {
    font-size: 6rem;
  }
  #popgary {
    margin-top: 30px;
  }

  #poppic {
    margin-top: 30px;
    width: 300px;
    height: 200px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #poppic {
    width: 550px;
    height: 450px;
  }

  #map {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .btn {
    margin-top: 25px;
  }
  .deli {
    margin: 0;
  }
  .hours {
    text-align: center;
  }
  .tagLine {
    font-size: 25px;
  }
  .headline {
    font-size: 6rem;
  }

  #popgary {
    margin-top: 30px;
  }

  #poppic {
    margin-top: 30px;
    width: 300px;
    height: 200px;
  }

  #map {
    width: 250px;
    height: 250px;
  }
}

btn:focus,
a:focus {
  outline: none;
}
.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}
