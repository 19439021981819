.cateringHeader {
  padding-top: 110px;
  background: url("../img/pasta.jpg");
  width: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 65px;
}

h5 {
  text-transform: uppercase;
  font-weight: bold;
}

.titleText {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  padding-top: 50px;
}

.deliTable {
  margin-left: 350px;
  margin-right: 350px;
}

.calltoorder {
  text-transform: uppercase;
  font-weight: bold;
  color: #c62828;
  margin-top: 15px;
}

@media (max-width: 567px) {
  .deliTable {
    margin-left: 0;
    margin-right: 0;
  }
  h1 {
    font-size: 45px;
  }
  h5 {
    font-size: 20px;
  }
}
