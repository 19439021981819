.WTHeader {
  padding-top: 110px;
  background: url("../img/blackmarble.jpg");
  width: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 60px;
}

h5 {
  text-transform: uppercase;
  font-weight: bold;
}

.titleText {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  padding-top: 50px;
}

.meatsTable {
  margin-left: 350px;
  margin-right: 350px;
}
.recession {
  margin-bottom: 50px;
}

.calltoorder {
  text-transform: uppercase;
  font-weight: bold;
  color: #c62828;
  margin-top: 15px;
}

@media (max-width: 567px) {
  .recession,
  .meatsTable {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 768px) {
  .recession,
  .meatsTable {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .recession,
  .meatsTable {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 390px) {
    .wtH1 {
      font-size: 40px;
    }
  }
}
